import React, { Component } from 'react';
import Home from '../sections/Home';
import About from '../sections/About';
import Portfolio from '../sections/Portfolio';
import Contact from '../sections/Contact';
import scrollToHash from '../utils/scrollToHash';

export default class Main extends Component{
  componentDidMount(){
    let { pathname } = window.location;
    if(pathname === '/') pathname = '/home';
    const element = pathname.replace('/','#');
    setTimeout(() => scrollToHash(null, element), 100);
  }
  
  render(){
    const components = {
      'home': Home,
      'about': About,
      'portfolio': Portfolio,
      'contact': Contact
    }
    const sections = this.props.order.map(sectionName => {
      const section = this.props.sections[sectionName];
      const Component = components[section.component];
      return <Component key={sectionName} id={sectionName} {...section} />
    });

    return <>
      {sections}
    </>
  }
}