import React from 'react';

export default class ErrorBoundary extends React.Component{
  state = {hasError: false}
  static getDerivedStateFromError(error){
    return {hasError: true};
  }
  render(){
    if(this.state.hasError){
      return <section className="error-boundary">
        <h2>Oops! Parece que hubo un problema. Por favor déjanos saber que sucedió enviándonos un correo a <a href="mailto:informes@lacouturiere.mx">informes@lacouturiere.mx</a> y lo resolveremos a la brevedad.</h2>
        <a href={process.env.REACT_APP_BASE_URL}>Regresar al inicio</a>
      </section>
    }
    return this.props.children;
  }
}