import React, { Component } from "react";
import MDReactComponent from "markdown-react-js";
import { Helmet } from "react-helmet";
import styles from "./Detail.module.css";

export default class Detail extends Component {
  state = {
    showPayform: false,
  };
  render() {
    const { courses, match } = this.props;
    const courseList = courses.content.value;
    const selectedCourse = courseList.find(
      (course) => course.callToAction.url === match.params.course
    );
    const { gallery, title, description, callToAction } = selectedCourse;
    return (
      <main className={styles.detail}>
        <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={`https://lacouturiere.mx/cursos/${callToAction.url}`}
          />
          {gallery.map((image, index) => (
            <meta
              key={index}
              property="og:image"
              content={`https://lacouturiere.mx${image}`}
            />
          ))}
          <title>{title}</title>
        </Helmet>
        <section className={styles.imageContainer}>
          <div className={styles.description}>
            <h2>{title}</h2>
            {description && (
              <MDReactComponent
                text={description}
                tags={{ html: "article", h1: "h2", h2: "h3" }}
              />
            )}
          </div>
        </section>
        <MDReactComponent
          className={styles.content}
          text={callToAction.content}
          tags={{ html: "section", h1: "h3" }}
        />
        <section>
          <ul className={styles.gallery}>
            {gallery.map((image, index) => (
              <li key={index} style={{ backgroundImage: `url(${image})` }}></li>
            ))}
          </ul>
        </section>
        <p className={styles.doubts}>
          ¿Aún tienes dudas? envíanos un correo a{" "}
          <a href="mailto:informes@lacouturiere.mx">informes@lacouturiere.mx</a>{" "}
          o escríbenos en messenger a{" "}
          <a
            href="http://m.me/lacouturieremx"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://m.me/lacouturieremx
          </a>
        </p>
      </main>
    );
  }
}
