import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import './Header.css';
import data from '../data/site.json';

class Header extends React.Component{
  state = {
    show: !data.sections.home.logo
  }
  constructor(props){
    super(props);
    if(data.sections.home.logo){
      window.addEventListener('scroll', this.enableScrollHandler);
    }
  }
  enableScrollHandler = e => {
    const section = document.querySelector('section:nth-of-type(2)');
    if(this.props.location.pathname !== '/' || (section && window.pageYOffset > section.offsetTop)){
      this.setState({show: true});
    }else{
      this.setState({show: false});
    }
  }

  componentDidMount(){
    if(this.props.location.pathname !== '/'){
      this.setState({show: true});
    }
  }

  componentWillReceiveProps(next){
    if(next.location.pathname !== '/'){
      this.setState({show: true});
    }else{
      this.setState({show: false});
    }
  }

  render(){
    const HeaderContainer = styled.header`
      background-color: ${data.header.backgroundColor.value || null};
    `;
    const Title = styled.h1`
      font-family: ${data.header.title.fontType.value || 'sans-serif'};
      font-size: ${data.header.title.fontSize.value || '1em'};
    `;
    return(
      <HeaderContainer className="site-header">
        <nav>
          {!this.state.show && <div></div>}
          {this.state.show && <Title className={data.header.logo.value ? 'hidden':''}>{data.header.title.value}</Title>}
          {this.state.show && <img className={data.header.logo.value ? 'logo':'hidden'} src={data.header.logo.value} alt={data.header.title.value} />}
          <MobileMenu sections={data.sections} />
          <DesktopMenu sections={data.sections} />
        </nav>
      </HeaderContainer>
    )
  }
}

export default withRouter(Header);