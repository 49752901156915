import React from "react";
import styled from "styled-components";
import GalleryItem from "../UI/GalleryItem";
import styles from "./Portfolio.module.css";

export default function Portfolio({ id, background, title, caption, content }) {
  const Section = styled.section`
    background: ${background.value || null};
  `;
  const Title = styled.h2`
    font-family: ${title.fontFamily.value || "sans-serif"};
    font-size: ${title.fontSize.value || "2em"};
    color: ${title.color.value || "#000"};
  `;
  const Subtitle = styled.p`
    font-family: ${caption.fontFamily.value || "sans-serif"};
    font-size: ${caption.fontSize.value || "1em"};
    color: ${caption.color.value || "#000"};
  `;
  return (
    <Section id={id} className={styles.portfolio}>
      <Title>{title.value}</Title>
      <Subtitle>{caption.value}</Subtitle>
      <ul className={styles.portfolioList}>
        {content.value.map((item, index) => (
          <li key={index}>
            <GalleryItem parentId={id} content={content} {...item} />
          </li>
        ))}
      </ul>
    </Section>
  );
}
