import React, { Component } from 'react';
import styles from './Tyc.module.css';
import Helmet from 'react-helmet';

export default class Tyc extends Component {
  render() {
    return <main className={styles.tyc}>
      <Helmet>
        <meta property="og:title" content="Términos y condiciones" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://lacouturiere.mx/tyc`} />
        <title>Términos y condiciones</title>
      </Helmet>
      <section className={styles.description}>
        <h2>Términos y condiciones</h2>
        <h3>1. Alcance</h3>
        <p>Estos términos y condiciones aplican a todas las actividades de formación (capacitaciones, cursos, talleres, seminarios y eventos similares) ofrecidas por La Couturière.
        Le recomendamos que lea con detenimiento el contenido de los términos y condiciones generales antes de generar el pago de cualquiera de los servicios de capacitación que ofrecemos.
Los términos y condiciones generales no son negociables y una vez aceptados son obligatorios para todos los usuarios de nuestros servicios.</p>
        <h3>2. Registro</h3>
        <p>Las solicitudes de inscripción a los cursos se realizarán exclusivamente a través del formato de registro publicado o compartido a través de nuestras redes sociales o en nuestro lugar de trabajo; nosotros enviaremos la confirmación de su registro al usuario de red social proporcionado por el estudiante.
El registro implica el reconocimiento y aceptación de nuestros términos y condiciones generales. El desconocimiento del contenido de los términos y condiciones generales no le exime de la responsabilidad.</p>
        <h3>3. Servicios</h3>
        <p>Nuestro servicio incluye:</p>
        <ul>
          <li>Curso de aprendizaje;</li>
          <li>Constancia de participación. Esta será otorgada a los participantes que asistan al menos al 80% de la capacitación y obtengan una calificación aprobatoria a criterio del instructor.</li>
          <li>Instructores capacitados y con amplia experiencia.</li>
          <li>Materiales, herramientas y equipo necesarios para la actividad de formación.</li>
        </ul>
        <h3>4. Precios y condiciones de pago</h3>
        <p>Los precios de los cursos ofertados son los autorizados por el instructor en turno. Para obtener un descuento este deberá haber sido publicado en alguna de nuestras redes sociales y el participante deberá presentar una prueba fehaciente del reconocimiento de este descuento, de lo contrario se le hará el cargo publicado al público en general.
        Los costos de los cursos están especificados en el calendario publicado en nuestra página web y redes sociales. Nuestros precios ya incluyen IVA. El pago puede realizarse en varias exhibiciones por medio de depósito en efectivo, transferencia electrónica, tarjeta de débito ó tarjeta de crédito y solo por el monto exacto del curso, en caso de ser un pago en varias exhibiciones, se deberán mostrar los comprobantes de cada una de ellas por separado.
        Nosotros enviaremos la confirmación de su inscripción al medio electrónico proporcionado por usted, una vez haya sido confirmado el pago correspondiente.</p>
        <h3>5. Facturación</h3>
        <p>El participante que requiera de factura deberá solicitarla al momento de su pago en el medio electrónico a tráves del cual está haciendo la notificación del mismo. Factura que no sea solicitada en el momento de su pago, no podrá ser emitida posteriormente.</p>
        <h3>6. Derechos reservados de modificación</h3>
        <p>La Couturière se reserva el derecho de cambiar los cursos por razones justificables (como, sin limitación, cuando haya pocos participantes o si el instructor se encuentra incapacitado) de posponer o cancelar los servicios y/o de asignar un nuevo instructor.</p>
        <p>Si las fechas de los cursos son cambiadas, los participantes inscritos pueden cancelar su inscripción enviando una notificación por medio electrónico a cualquiera de nuestras redes sociales dentro de los 2 días hábiles siguientes al recibo de la notificación del cambio, en caso de no solicitar la baja de inscripción dentro del plazo establecido no será posible el reembolso del pago realizado. En el caso en que el servicio de capacitación sea cancelado por La Couturière, la responsabilidad de La Couturière estará limitada únicamente al reintegro del precio total, si dicho pago ha sido realizado con anterioridad a La Couturière.</p>
        <h3>7. Cancelaciones por los participantes registrados</h3>
        <p>El costo de la capacitación será cobrado a los participantes registrados y su inscripción será confirmada incluso en el caso de inasistencia. Los participantes pueden cancelar su inscripción en el curso comunicando su decisión a cualquiera de nuestros medios electrónicos disponibles. Si la cancelación se realiza dentro de 1 día y horas hábiles anteriores al inicio del curso se devolverá el 100% del valor de la misma. Si la cancelación ocurre posterior al inicio del curso, no habrá devoluciones. La fecha y hora de cancelación corresponde al día en que La Couturière recibe comunicación vía medios electrónicos de ello. En cursos cuyo control financiero sea realizado fuera del control de La Couturiére, los términos de cancelación pueden variar.</p>
        <h3>8. Ley aplicable</h3>
        <p>Este acuerdo está sujeto a la normatividad vigente en los Estados Unidos Mexicanos.</p>
        <h3>9. Derechos de autor</h3>
        <p>El material distribuido en los cursos en cualquier formato(escrito, audiovisual o de audio) no puede ser reproducido bajo ningún propósito sin el consentimiento oficial de La Couturière y solamente puede ser utilizado para propósitos explícitamente autorizados por La Couturière. El software utilizado o proporcionado por La Couturière para la realización de las capacitaciones no puede ser removido, copiado (total o parcialmente) ni usado de cualquier otra manera no autorizada. Las grabaciones de video o sonido podrán ser permitidas a criterio y discreción del instructor en curso.</p>
        <h3>10. Responsabilidad</h3>
        <p>Toda la información técnica proporcionada en los cursos y la documentación de las capacitaciones, es correcta de acuerdo con nuestro conocimiento. Ninguna responsabilidad será asumida por la exactitud de la información proporcionada en los cursos o en los documentos de la capacitación.</p>
        <p>La Couturière responde por el cumplimiento pleno de sus obligaciones.  En relación con los perjuicios, las Partes únicamente responderán por el daño emergente derivado de las acciones u omisiones que le sean directamente imputables.  En ningún evento las Partes responderán por daños indirectos, consecuenciales, y/o pérdida de información.  En todo caso, la responsabilidad de las Partes en ningún evento excederá el cien por ciento del valor de la capacitación, salvo en los eventos de dolo o culpa grave, en los cuales la responsabilidad será plena.</p>
      </section>
      <p className={styles.doubts}>¿Aún tienes dudas? Comunícate al teléfono <a href="tel:5551299616">55 5129 9616</a>, envíanos un correo a <a href="mailto:informes@lacouturiere.mx">informes@lacouturiere.mx</a> o escríbenos en messenger a <a href="http://m.me/lacouturieremx" target="_blank" rel="noopener noreferrer">http://m.me/lacouturieremx</a></p>
    </main>
  }
}