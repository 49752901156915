import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import './DesktopMenu.css';
import data from '../data/site.json';

export default function DesktopMenu({ sections }) {
  const StyledLink = styled(Link)`
    font-family: ${data.header.links.fontType.value || 'sans-serif'};
    font-size: ${data.header.links.fontSize.value || '1em'};
    color: ${data.header.links.color.value || '#000'};
    &:hover{
      color: ${data.header.links.hoverColor.value || '#FFF'};
    }
  `;
  let links = [];
  if (sections) {
    links = Object.keys(sections).map(sectionName => {
      const { refName, title } = sections[sectionName];
      return { sectionName, refName, title };
    });
  }
  return (
    <div className="desktop-menu">
      <ul>
        {links.map(link => <li key={link.sectionName}>
          <StyledLink className="menu-item"
            to={`/${link.sectionName !== 'home' ? link.sectionName : ''}`}>
            {link.refName || link.title.value}
          </StyledLink></li>)}
        <li key='tyc'>
          <StyledLink className="menu-item" to='tyc'>Términos y Condiciones</StyledLink></li>
      </ul>
    </div>
  )
}