import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './UI/Header';
import ErrorBoundary from './UI/ErrorBoundary';
import './App.css';
import data from './data/site.json';
import Main from './pages/Main';
import Detail from './pages/Detail';
import Tyc from './pages/Tyc';

function App() {
  const fontSources = data.fontFamilies.value.join('|').replace(' ', '+');
  const mainRoutes = Object.keys(data.sections).map(sectionName => {
    return <Route key={sectionName} exact path={`/${sectionName}`} render={routeProps => <Main order={data.mainSectionsOrder} sections={data.sections} {...routeProps} />} />
  })
  return (
    <ErrorBoundary>
      <style>
        @import url({`https://fonts.googleapis.com/css?family=${fontSources}`});
      </style>
      <Helmet>
        <meta property="og:title" content="La Couturière" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lacouturiere.mx/" />
        <meta property="og:image" content="https://lacouturiere.mx/lacouturiere.jpg" />
      </Helmet>
      <Header />
      <Switch>
        <Route exact path='/' render={routeProps => <Main order={data.mainSectionsOrder} sections={data.sections} {...routeProps} />} />
        {mainRoutes}
        <Route exact path='/tyc' component={Tyc} />
        <Route exact path='/cursos/:course' render={routeProps => <Detail courses={data.sections.cursos} {...routeProps} />} />}/>
        <Route render={routeProps => {
          return <p>Not Found</p>
        }} />
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
