import React from 'react';
import styled from 'styled-components';
import MDReactComponent from 'markdown-react-js';
import styles from './About.module.css';

export default function About({id, background,title,content,image}){
  
  const Section = styled.section`
    background: ${background.value || null};
  `;
  const Title = styled.h2`
    font-family: ${title.fontFamily.value || 'sans-serif'};
    font-size: ${title.fontSize.value || '2em'};
    color: ${title.color.value || '#000'};
  `;
  const Title2 = styled.h3`
    font-family: ${title.fontFamily.value || 'sans-serif'};
    font-size: '1em';
    color: ${title.color.value || '#000'};
  `;
  const Title3 = styled.h4`
    font-family: ${title.fontFamily.value || 'sans-serif'};
    font-size: '1em';
    color: ${title.color.value || '#000'};
  `;
  const Title4 = styled.h5`
    font-family: ${title.fontFamily.value || 'sans-serif'};
    font-size: '1em';
    color: ${title.color.value || '#000'};
  `;
  const Paragraph = styled.p`
    font-family: ${content.fontFamily.value || 'sans-serif'};
    font-size: ${content.fontSize.value || '2em'};
    color: ${content.color.value || '#000'};
  `;
  const ParagraphImage = styled.p`
    background-image: url(${image.value});
  `;
  return(
    <Section id={id} className={styles.about}>
      <Title>{title.value}</Title>
      <MDReactComponent text={content.value} tags={{html: 'div', p: Paragraph, h1: Title2, h2: Title3, h3: Title4}} />
      <ParagraphImage className={styles.image}></ParagraphImage>
    </Section>
  )
}