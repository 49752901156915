import React from 'react';
import styled from 'styled-components';
import scrollToHash from '../utils/scrollToHash';
import styles from './Home.module.css';

export default function Home({id, background, title, content, callToAction, logo}){
  
  const Section = styled.section`
    background: ${background.value || null};
    background-size: cover;
  `;
  const Title = styled.h2`
    font-family: ${title.fontFamily.value || 'sans-serif'};
    font-size: ${title.fontSize.value || '2em'};
    color: ${title.color.value || '#000'};
  `;
  const Paragraph = styled.p`
    font-family: ${content.fontFamily.value || 'sans-serif'};
    font-size: ${content.fontSize.value || '2em'};
    color: ${content.color.value || '#000'};
  `;
  const CallToAction = styled.a`
    background: ${callToAction.background.value || '#FFF'};  
    font-family: ${callToAction.fontFamily.value || 'sans-serif'};
    font-size: ${callToAction.fontSize.value || '2em'};
    color: ${callToAction.color.value || '#000'};
  `;
  const CircledCTA = styled.a`
    background: ${callToAction.background.value || '#FFF'};
    font-family: ${callToAction.fontFamily.value || 'sans-serif'};
    font-size: ${callToAction.fontSize.value || '2em'};
    color: ${callToAction.color.value || '#000'};
    border: 1px solid ${callToAction.borderColor.value || '#000'};
    width: 2em;
    height: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    bottom: 1em;
    left: calc(50% - 1em);
  `;
  const Icon = styled.i`
    height: 10px;
  `;
  const Image = styled.img`
    width: 100%;
  `;
  let child;
  let cta;
  if(logo){
    child = <Image src={logo.value} alt={title.value} />
  }else{
    child = <>
      <Title>{title.value}</Title>
      {content.value.map((paragraph, index) => <Paragraph key={index}>{paragraph}</Paragraph>)}
    </>
  }
  if(callToAction.value === "down"){
    cta = <CircledCTA href={callToAction.action.value} onClick={scrollToHash}>
      <Icon className="icon ion-md-arrow-round-down"></Icon>
    </CircledCTA>
  }else{
    cta = <CallToAction href={callToAction.action.value} className={styles.callToAction} target="_blank">{callToAction.value}</CallToAction>
  }
  return (
    <Section id={id} className={styles.home}>
      <article>
        {child}
        {cta}
      </article>
    </Section>
  );
}