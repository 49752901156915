import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import './MobileMenu.css';
import data from '../data/site.json';

export default class MobileMenu extends React.Component {
  state = {
    showMenu: false
  }
  toggleMenu = (e) => {
    e.preventDefault();
    this.setState({ showMenu: !this.state.showMenu });
  }
  hideMenu = () => {
    this.setState({ showMenu: false });
  }
  render() {
    const { sections } = this.props;
    const Menu = styled.a`
      font-family: ${data.header.links.fontType.value || 'sans-serif'};
      color: ${data.header.links.color.value || '#000'};
    `;
    const Aside = styled.aside`
      background: ${data.header.links.backgroundColor.value || '#FFF'};
    `;
    const StyledLink = styled(Link)`
      font-family: ${data.header.links.fontType.value || 'sans-serif'};
      font-size: ${data.header.links.mobileFontSize.value || '1em'};
      color: ${data.header.links.color.value || '#000'};
      &:hover{
        color: ${data.header.links.hoverColor.value || '#FFF'};
      }
    `;
    let links = [];
    if (sections) {
      links = Object.keys(sections).map(sectionName => {
        const { refName, title } = sections[sectionName];
        return { sectionName, refName, title };
      });
    }
    return (
      <div className="mobile-menu">
        <Menu onClick={this.toggleMenu} className="menu-icon" href="#menu">
          <span className="icon ion-md-menu"></span>
        </Menu>
        <CSSTransition
          in={this.state.showMenu}
          timeout={350}
          classNames="mobile-menu-content"
          unmountOnExit
          appear
        >
          <Aside>
            <ul>
              {links.map(link => <li key={link.sectionName}>
                <StyledLink className="menu-item" to={link.sectionName !== 'home' ? link.sectionName : ''} onClick={e => {
                  this.hideMenu();
                }}>{link.refName || link.title.value}</StyledLink></li>)}
              <li key='tyc'>
                <StyledLink className="menu-item" to='tyc' onClick={e => {
                  this.hideMenu();
                }}>Términos y Condiciones</StyledLink></li>
            </ul>
          </Aside>
        </CSSTransition>
      </div>
    )
  }
}