import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MDReactComponent from "markdown-react-js";
import styles from "./GalleryItem.module.css";

export default function GalleryItem({
  parentId,
  callToAction,
  content,
  description,
  photo,
  price,
  title,
}) {
  const Paragraph = styled.p`
    font-family: ${content.fontFamily.value || "sans-serif"};
    font-size: ${content.fontSize.value || "2em"};
    color: ${content.color.value || "#000"};
  `;
  const Title = styled.h3`
    font-family: ${content.fontFamily.value || "sans-serif"};
    font-size: ${content.fontSize.value || "2em"};
    color: ${content.color.value || "#000"};
  `;
  return (
    <div className={styles.galleryItem}>
      <h3>{title}</h3>
      <div className={styles.photo} style={{ backgroundImage: `url(${photo}` }}>
        <div className={styles.caption}>
          {description && (
            <MDReactComponent
              text={description}
              tags={{ html: "div", p: Paragraph, h1: Title }}
            />
          )}
          <Link to={`/${parentId}/${callToAction.url}`}>
            {callToAction.value}
          </Link>
        </div>
      </div>
    </div>
  );
}
