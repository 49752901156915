import React from "react";
import styled from "styled-components";
import styles from "./Contact.module.css";

export default function Contact({
  id,
  background,
  title,
  caption,
  tangible,
  mail,
  facebook,
  twitter,
  instagram,
}) {
  const Section = styled.section`
    background: ${background.value || null};
  `;
  const Title = styled.h2`
    font-family: ${title.fontFamily.value || "sans-serif"};
    font-size: ${title.fontSize.value || "2em"};
    color: ${title.color.value || "#000"};
  `;

  const Subtitle = styled.p`
    font-family: ${caption.fontFamily.value || "sans-serif"};
    font-size: ${caption.fontSize.value || "1em"};
    color: ${caption.color.value || "#000"};
  `;

  return (
    <Section id={id} className={styles.contact}>
      <Title>{title.value}</Title>
      <Subtitle>{caption.value}</Subtitle>
      <div className={styles.content}>
        <div className={styles.networks}>
          {mail.value && (
            <a
              target="_blank"
              href={`mailto:${mail.value}`}
              rel="noopener noreferrer"
            >
              <span className="icon ion-md-mail"></span>
              {mail.value}
            </a>
          )}
          {facebook.value && (
            <a
              target="_blank"
              href={`https://www.facebook.com/${facebook.value}`}
              rel="noopener noreferrer"
            >
              <span className="icon ion-logo-facebook"></span>
              {facebook.value}
            </a>
          )}
          {twitter.value && (
            <a
              target="_blank"
              href={`https://twitter.com/${twitter.value}`}
              rel="noopener noreferrer"
            >
              <span className="icon ion-logo-twitter"></span>
              {twitter.value}
            </a>
          )}
          {instagram.value && (
            <a
              target="_blank"
              href={`https://instagram.com/${instagram.value}`}
              rel="noopener noreferrer"
            >
              <span className="icon ion-logo-instagram"></span>
              {instagram.value}
            </a>
          )}
        </div>
      </div>
    </Section>
  );
}
