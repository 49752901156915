export default (e, element) => {
  let selected, hashValue;
  if(e){
    e.preventDefault();
    selected = document.querySelector(e.currentTarget.attributes.href.value);
    hashValue = e.currentTarget.attributes.href.value.replace('#','/');
    if(hashValue === '/home') hashValue = '/';
    window.history.pushState({}, "", hashValue);
  }else{
    selected = document.querySelector(element);
  }
  if(selected) selected.scrollIntoView({behavior: 'smooth'});
}